<template>
  <div>
    <!-- 시나리오 목록 -->
    <c-table
      ref="table"
      title="LBL0001165"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="grid.data"
      :merge="grid.merge"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :editable="false"
    >
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='customCol'">
          <component
            :is="imprComponent"
            :col="col"
            :props="props"
            :inputEditable="false"
            :requestContentsCols="requestContentsCols"
            tableKey="ramChecklistAssessScenarioId"
            ibmTaskTypeCd="ITT0000010"
            ibmTaskUnderTypeCd="ITTU000010"
            @imprChange="imprChange"
          />
        </template>
        <template v-else-if="col.name==='picture'">
          <q-icon
              style="font-size: 30px; cursor:pointer;"
              class="text-primary" 
              name="image"
              @click.stop="openImprPicture(props)" />
        </template>
        <template v-else-if="col.name==='checkItemName'">
          <template v-if="props.row.checkItemId && props.row.ramCheckItemClassCd !== 'RCIC000015'">
            <!-- 작업, 기계·기구, 유해인자 & link 걸린 대상 -->
            <q-btn
              flat
              class="tableinnerBtn"
              align="left"
              color="blue-6"
              :label="props.row[col.name]"
              @click.stop="openItem(props.row)" />
          </template>
          <template v-else>
            <!-- 기타 & link 걸리지 않은 대상 -->
            {{props.row[col.name]}}
          </template>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'checklist-scenario-TL',
  props: {
    param: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',
        ramStepCd: '',
      }),
    },
  },
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'processCd' },
          { index: 1, colName: 'checkClassGroup' },
          { index: 2, colName: 'checkItemGroup' },
        ],
        columns: [
          {
            name: 'processName',
            field: 'processName',
            label: 'LBLPROCESS',
            align: 'left',
            style: 'width:300px',
            sortable: false,
          },
          {
            name: 'ramCheckItemClassName',
            field: 'ramCheckItemClassName',
            // 분류
            label: 'LBLCLASSIFICATION',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'checkItemName',
            field: 'checkItemName',
            // 평가대상
            label: 'LBL0001028',
            align: 'center',
            style: 'width:150px',
            sortable: false,
            type: 'custom',
          },
          {
            name: 'checklistQuestion',
            field: 'checklistQuestion',
            // 체크리스트 문항
            label: 'LBL0001092',
            align: 'left',
            style: 'width:300px',
            sortable: false,
          },
          {
            name: 'relationLaw',
            // 관련법규
            label: 'LBL0001021',
            align: 'left',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'currentSafetyMeasures',
            field: 'currentSafetyMeasures',
            // 현재안전조치
            label: 'LBL0001025',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'ramInspectResultName',
            field: 'ramInspectResultName',
            // 결과
            label: 'LBL0001007',
            align: 'center',
            style: 'width:90px',
          },
          {
            name: 'picture',
            field: 'picture',
            label: '개선 전/후 사진',
            align: 'center',
            type: 'custom',
            style: 'width:60px',
            sortable: false,
          },
          {
            name: 'improvementMeasures',
            field: 'improvementMeasures',
            // 추가 리스크관리 계획
            label: 'LBL0001122',
            align: 'left',
            style: 'width:200px',
            sortable: false
          },
          {
            name: 'impr',
            field: 'impr',
            // 개선진행
            label: 'LBL0001178',
            align: 'center',
            child: [
              {
                name: 'customCol',
                field: 'customCol',
                label: 'LBLIMPRNO_PROGRESS',
                align: 'center',
                style: 'width:130px',
                type: 'custom',
                sortable: false
              },
            ]
          },
        ],
        data: [],
        height: 'auto' //'600px'
      },
      imprComponent: () => import(`${'@/pages/common/ibm/tableImpr.vue'}`),
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
    requestContentsCols() { 
      return ['ramCheckItemClassName', 'checkItemName'];
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.listUrl = selectConfig.ram.checklist.scenario.list.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        ramRiskAssessmentPlanId: this.param.ramRiskAssessmentPlanId,
      }
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    imprChange() {
      this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
    },
    openItem(row) {
      if (row.ramCheckItemClassCd === 'RCIC000001') {
        this.popupOptions.title = 'LBL0001093'; // 안전작업표준 상세
        this.popupOptions.param = {
          mdmSopId: row.checkItemId,
        };
        this.popupOptions.target = () => import(`${'@/pages/mdm/pim/sopDetail.vue'}`);
      } else if (row.ramCheckItemClassCd === 'RCIC000005') {
        this.popupOptions.title = "LBL0000956"; // 유해위험기계기구 상세
        this.popupOptions.param = {
          hhmHazardousMachineryId: row.checkItemId,
        };
        this.popupOptions.target = () => import(`${"@/pages/sop/mim/hazardEquipment/hazardEquipmentClassDetail.vue"}`);
      } else {
        this.popupOptions.title = 'LBL0001094'; // 유해인자 상세
        this.popupOptions.param = {
          hazardCd: row.checkItemId,
        };
        this.popupOptions.target = () => import(`${"@/pages/sai/wmr/harmfulFactorsDetail.vue"}`);
      }
      this.popupOptions.width = '90%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    openImprPicture(props) {
      this.popupOptions.title = 'LBL0001181'; // 개선 사진
      this.popupOptions.param = {
        ramChecklistAssessScenarioId: props.row.ramChecklistAssessScenarioId,
        sopImprovementIds: props.row.sopImprovementIds,
        disabled: true
      };
      this.popupOptions.target = () => import(`${'@/pages/ram/checklist/action/checklistScenarioImprPicture.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeScenarioImprPicturePopup;
    },
    closeScenarioImprPicturePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>
